<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>数据报表</span>
      </div>
      <div style="display:flex;justify-content:space-between;">
        <div class="search">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="选择日期">
              <el-date-picker
                :picker-options="pickerOptions"
                v-model="formInline.date"
                style="width: 215px"
                value-format="timestamp"
                type="date"
                size="small"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <a :href="'/conn-portal/wapi/v1/dingbian/export?date='+(formInline.date?formInline.date:new Date(new Date().toLocaleDateString()).getTime())" target="_blank">
            <el-button class="btn" style="margin-right: 10px" type="primary" size="small">导出报表</el-button>
          </a>
        </div>
      </div>
      <div class="table-list">
        <el-collapse accordion v-model="activeNames">
          <el-collapse-item v-for="(item,index) in tableData" :key="index" :name="'t'+index">
            <template slot="title">
              <span class="list-title">
                <i class="header-icon el-icon-time"></i>
                {{ item.date?dayjs(item.date).format('HH:mm:ss'):'-' }} - 
                {{ dayjs(item.date+3600000).format('HH:mm:ss') }}
              </span>
            </template>
            <el-table :ref="'t'+index" :data="item.contents" style="width: 100%" size="small">
              <el-table-column prop="" label="名称">
                <template slot-scope="scope">
                  <span>{{ scope.row.position?scope.row.position+'('+scope.row.waterworks+')':'-' }}</span>
                  <!-- <span style="margin-right: 10px;">{{ scope.row.waterworks }}</span>
                  <span style="color: #bbb;">-</span>
                  <span style="margin-left: 10px;">{{ '( '+scope.row.position+' )' }}</span> -->
                </template>
              </el-table-column>

              <!-- <el-table-column prop="pipelinePressure" label="管道压力">
                <template slot-scope="scope">
                  {{ scope.row.content?scope.row.content.pipelinePressure:0 }}
                </template>
              </el-table-column> -->

              <el-table-column prop="flowInstantaneous" label="瞬时流量（m³/h）">
                <template slot-scope="scope">
                  {{ scope.row.content?scope.row.content.flowInstantaneous:0 }}
                </template>
              </el-table-column>
              <el-table-column prop="fluidVelocity" label="瞬时流速（m/s）">
                <template slot-scope="scope">
                  {{ scope.row.content?scope.row.content.fluidVelocity:0 }}
                </template>
              </el-table-column>
              <el-table-column prop="positiveCumulativeFlow" label="正累积流量（m³）">
                <template slot-scope="scope">
                  {{ scope.row.content?scope.row.content.positiveCumulativeFlow:0 }}
                </template>
              </el-table-column>
              <!-- <el-table-column prop="reportTime" label="采集时间"></el-table-column> -->
              <!-- <el-table-column prop="negativeCumulativeFlow" label="负累积流量（m³）">
                <template slot-scope="scope">
                  {{ scope.row.content?scope.row.content.negativeCumulativeFlow:0 }}
                </template>
              </el-table-column> -->
            </el-table>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
  
<script>
import page from '@/components/page.vue'
import dayjs from "dayjs";
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {
        date: ''
      },
      tableData: [
        // {
        //   "date": 1697299200000,
        //   "contents": [
        //     {
        //       "content": {
        //           "pipelinePressure": 0.26,
        //           "flowInstantaneous": 19.16,
        //           "fluidVelocity": 2.24,
        //           "positiveCumulativeFlow": 209759,
        //       },
        //       "reportTime": '2023-10-15 23:00.03',
        //       "waterworks": "北物流",
        //       "position": "爱心小区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.28,
        //           "flowInstantaneous": 11.49,
        //           "fluidVelocity": 1.56,
        //           "positiveCumulativeFlow": 13154,
        //       },
        //       "reportTime": '2023-10-15 22.55.03',
        //       "waterworks": "一马路广华加油站",
        //       "position": "阳光佳苑",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.25,
        //           "flowInstantaneous": 6.49,
        //           "fluidVelocity": 0.75,
        //           "positiveCumulativeFlow": 80401,
        //       },
        //       "reportTime": '2023-10-15 22.50.03',
        //       "waterworks": "伯爵公馆路口",
        //       "position": "仁和小区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.27,
        //           "flowInstantaneous": 19.25,
        //           "fluidVelocity": 2.72,
        //           "positiveCumulativeFlow": 205339,
        //       },
        //       "reportTime": '2023-10-15 22.45.03',
        //       "waterworks": "一马路交警对换热站",
        //       "position": "龙湾一号",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.25,
        //           "flowInstantaneous": 17.32,
        //           "fluidVelocity": 2.35,
        //           "positiveCumulativeFlow": 76733,
        //       },
        //       "reportTime": '2023-10-15 22.40.03',
        //       "waterworks": "五马路大联社对面",
        //       "position": "博雅苑",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.27,
        //           "flowInstantaneous": 12.3,
        //           "fluidVelocity": 3.21,
        //           "positiveCumulativeFlow": 56231,
        //       },
        //       "reportTime": '2023-10-15 22:35.03',
        //       "waterworks": "一马路广华加油站",
        //       "position": "东方明珠",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.26,
        //           "flowInstantaneous": 7.26,
        //           "fluidVelocity": 0.84,
        //           "positiveCumulativeFlow": 85816,
        //       },
        //       "reportTime": '2023-10-15 22:30.03',
        //       "waterworks": "北物流",
        //       "position": "亿和小区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.27,
        //           "flowInstantaneous": 2.35,
        //           "fluidVelocity": 0.96,
        //           "positiveCumulativeFlow": 34216,
        //       },
        //       "reportTime": '2023-10-15 22.25.03',
        //       "waterworks": "伯爵公馆路口",
        //       "position": "劳动力小区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.28,
        //           "flowInstantaneous": 6.32,
        //           "fluidVelocity": 0.25,
        //           "positiveCumulativeFlow": 25461,
        //       },
        //       "reportTime": '2023-10-15 22.20.03',
        //       "waterworks": "五马路大联社对面",
        //       "position": "电力C区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.26,
        //           "flowInstantaneous": 16.32,
        //           "fluidVelocity": 1.35,
        //           "positiveCumulativeFlow": 21365,
        //       },
        //       "reportTime": '2023-10-15 22.15:03',
        //       "waterworks": "一马路广华加油站",
        //       "position": "世家B区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.26,
        //           "flowInstantaneous": 11.2,
        //           "fluidVelocity": 1.52,
        //           "positiveCumulativeFlow": 125710,
        //       },
        //       "reportTime": '2023-10-15 22:10.03',
        //       "waterworks": "伯爵公馆路口",
        //       "position": "富原B区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.29,
        //           "flowInstantaneous": 13.23,
        //           "fluidVelocity": 2.13,
        //           "positiveCumulativeFlow": 9564,
        //       },
        //       "reportTime": '2023-10-15 22.05.03',
        //       "waterworks": "北物流",
        //       "position": "欧陆经典",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.25,
        //           "flowInstantaneous": 9.32,
        //           "fluidVelocity": 0.56,
        //           "positiveCumulativeFlow": 36521,
        //       },
        //       "reportTime": '2023-10-15 22:00:03',
        //       "waterworks": "一马路广华加油站",
        //       "position": "景峰家园",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.28,
        //           "flowInstantaneous": 13.48,
        //           "fluidVelocity": 1.57,
        //           "positiveCumulativeFlow": 150660,
        //       },
        //       "reportTime": '2023-10-15 21:55:03',
        //       "waterworks": "北物流",
        //       "position": "蒙二中",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.29,
        //           "flowInstantaneous": 15.37,
        //           "fluidVelocity": 1.65,
        //           "positiveCumulativeFlow": 51263,
        //       },
        //       "reportTime": '2023-10-15 21:50.03',
        //       "waterworks": "伯爵公馆路口",
        //       "position": "南丁小区",
        //     },
        //     {
        //       "content": {
        //           "pipelinePressure": 0.28,
        //           "flowInstantaneous": 15.62,
        //           "fluidVelocity": 2.81,
        //           "positiveCumulativeFlow": 32516,
        //       },
        //       "reportTime": '2023-10-15 21:45.03',
        //       "waterworks": "五马路大联社对面",
        //       "position": "云喜世家",
        //     },
        //   ]
        // }
      ],
      roleId: 0,
      activeNames: 't0',
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d > n;
        }
      },
    };
  },
  watch: {
    activeNames(newV) {
      if(newV) {
        this.$refs[newV][0].$ready = false
      }
    }
  },
  methods: {
    dayjs,
    // 列表筛选查询
    onSubmit() {
      this.activeNames = 't0'
      this.loadListData()
    },
    // 获取列表数据
    loadListData() {
      let todayTime = new Date(new Date().toLocaleDateString()).getTime()
      this.loading = true
      this.$ajax.post('flowmeterDataQuery', {
        date: this.formInline.date?this.formInline.date:todayTime,
      }).then(res => {
        this.tableData = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {

  }
};
</script>
  
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
.man-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search {
  padding: 0 10px;
}
.list-title {
  font-weight: bold;
  user-select: none;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.el-collapse-item {
  margin: 5px 0;
  padding: 0px 20px;
}
/deep/.el-collapse-item__header {
  color: #202020;
  // display: none;--
}
/deep/.el-table {
  background-color: #FAFAFA;
  margin: 10px 0;
}
/deep/.el-table__header {
  width: 100% !important;
}
/deep/.el-table__body {
  width: 100% !important;
}
/deep/.el-table::before {
  height: 0;
}
/deep/.el-table__header th {
  color: #767779;
  font-weight: normal;
}
/deep/.el-table th:nth-child(1) {
  border-top-left-radius: 10px;
}
/deep/.el-table th:nth-last-child(2) {
  border-top-right-radius: 10px;
}
/deep/.el-table td {
  border: 0;//--
}
/deep/.el-table tbody tr:hover > td {
  background-color: #f5f9ff !important;
}
</style>